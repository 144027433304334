.comparison-slider-wrapper {
  position: relative;
  width: 100%;
  margin: 20px 0;
  background-color: white;
}

.comparison-slider-wrapper .comparison-slider {
  position: relative;
  width: 100%;
  margin: 0;
  border: 5px white solid;
  box-sizing: border-box;
}

.comparison-slider-wrapper .comparison-slider > img {
  width: 100%;
  height: auto;
  display: block;
}

.comparison-slider-wrapper .comparison-slider .overlay {
  display: none;
  position: absolute;
  width: 250px;
  bottom: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px;
  box-sizing: border-box;
  color: #DDD;
  text-align: right;
}

@media screen and (min-width: 767px) {
  .comparison-slider-wrapper .comparison-slider .overlay {
    display: block;
  }
}

.comparison-slider-wrapper .comparison-slider .resize {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  overflow: hidden;
}

.comparison-slider-wrapper .comparison-slider .resize > img {
  display: block;
}

.comparison-slider-wrapper .comparison-slider .resize .overlay {
  right: auto;
  left: 20px;
  text-align: left;
}

.comparison-slider-wrapper .comparison-slider .divider {
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  left: 50%;
  top: 0;
  bottom: 0;
  margin-left: -1px;
  cursor: ew-resize;
}

.comparison-slider-wrapper .comparison-slider .divider:before {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  left: -9px;
  top: 50%;
  margin-top: -10px;
  background-color: white;
  transform: rotate(45deg);
  transition: all 0.1s ease-in-out;
}

.comparison-slider-wrapper .comparison-slider .divider:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: -5px;
  top: 50%;
  margin-top: -6px;
  background-color: white;
  transform: rotate(45deg);
  transition: all 0.1s ease-in-out;
}

.comparison-slider-wrapper .caption {
  position: relative;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 12px;
  font-style: italic;
}

