@font-face {
	font-family: 'Font Awesome 5 Free';
	src: url(../../fonts/fa-regular-400.eot) format('embedded-opentype'),
	url(../../fonts/fa-regular-400.eot) format('embedded-opentype'),
	url(../../fonts/fa-regular-400.woff2) format('woff2'),
	url(../../fonts/fa-regular-400.woff) format('woff'),
	url(../../fonts/fa-regular-400.ttf) format('truetype'),
	url(../../fonts/fa-regular-400.svg) format('svg');
	font-weight: 400;
	font-style: normal;
	font-display: auto;
}

@font-face {
	font-family: 'Font Awesome 5 Free';
	src: url(../../fonts/fa-solid-900.eot) format('embedded-opentype'),
	url(../../fonts/fa-solid-900.eot) format('embedded-opentype'),
	url(../../fonts/fa-solid-900.woff2) format('woff2'),
	url(../../fonts/fa-solid-900.woff) format('woff'),
	url(../../fonts/fa-solid-900.ttf) format('truetype'),
	url(../../fonts/fa-solid-900.svg) format('svg');
	font-weight: 900;
	font-style: normal;
	font-display: auto;
}
