@font-face {
	font-family: 'Font Awesome 5 Brands';
	src: url(../../fonts/fa-brands-400.eot) format('embedded-opentype'),
	url(../../fonts/fa-brands-400.eot) format('embedded-opentype'),
	url(../../fonts/fa-brands-400.woff2) format('woff2'),
	url(../../fonts/fa-brands-400.woff) format('woff'),
	url(../../fonts/fa-brands-400.ttf) format('truetype'),
	url(../../fonts/fa-brands-400.svg) format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: auto;
}
