.password-container {
    position: relative;
    width: 100%;
}

#password {
    padding-right: 30px; /* Adjust the padding to make room for the icon */
}

#togglePassword {
    position: absolute;
    right: 10px; /* Adjust as necessary for alignment */
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

#toggleConfirmPassword {
    position: absolute;
    right: 10px; /* Adjust as necessary for alignment */
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.comingSoonOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%);
    filter: blur(2px);
    -webkit-filter: blur(2px);
    background-color: rgba(0, 0, 0, 0.51);
    display: flex;
    flex-direction: column;
    justify-content: center;
    horiz-align: center;
}

.comingSoonOverlayText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bolder;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    horiz-align: center;
    color: white;
}

.gradientHeadingBig {
    background: -webkit-linear-gradient(70deg, #ffffff, #0058ff);
    background-size: 400% auto;
    background-clip: text;
    font-size: 110px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: AnimationName 3s ease-in-out infinite;
    -moz-animation: AnimationName 3s ease-in-out infinite;
    animation: AnimationName 3s ease-in-out infinite;
}


@media (max-width: 340px) {
    .gradientHeadingBig {
        font-size: 25px; /* Adjust the size as needed */
    }
}

@media (max-width: 575px) {
    .gradientHeadingBig {
        font-size: 25px; /* Adjust the size as needed */
    }
}

@media (max-width: 780px) {
    .gradientHeadingBig {
        font-size: 30px; /* Adjust the size as needed */
    }
}

@media (max-width: 1030px) {
    .gradientHeadingBig {
        font-size: 40px; /* Adjust the size as needed */
    }
}

@media (max-width: 1400px) {
    .gradientHeadingBig {
        font-size: 50px; /* Adjust the size as needed */
    }
}


@media (max-width: 1450px) {
    .landingCardsContainer {
        height: 40%;
        width: 40% !important;
    }
}

@media (max-width: 2570px) {
    .landingCardsContainer {
        height: 40%;
        width: 40% !important;
    }
}

@-webkit-keyframes AnimationName {
    0% {
        background-position: 76% 0%
    }
    50% {
        background-position: 25% 100%
    }
    100% {
        background-position: 76% 0%
    }
}

@-moz-keyframes AnimationName {
    0% {
        background-position: 76% 0%
    }
    50% {
        background-position: 25% 100%
    }
    100% {
        background-position: 76% 0%
    }
}

@keyframes AnimationName {
    0% {
        background-position: 76% 0%
    }
    50% {
        background-position: 25% 100%
    }
    100% {
        background-position: 76% 0%
    }
}

.gradientHeading {
    background: -webkit-linear-gradient(45deg, #0023ff, #e800ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradientHeadingSecond {
    background: -webkit-linear-gradient(45deg, #aab6ff, #f49bff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradientHeadingThird {
    background: -webkit-linear-gradient(45deg, #ffffff, #f81774);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.borderText {
    border-radius: 10px;
    background-color: blueviolet;
    transition-duration: 0.4s;
    padding: 30px;
    color: white;
}

.borderText:hover {
    border-radius: 10px;
    background-color: blue;
}


.landingCardGIF::after {
    content: '"An anime illustration of a girl with a majestic dragon in a mountain sanctuary, dramatic light, hd, 8K UHD"';
    position: absolute;
    border: 2px solid purple;
    border-radius: 10px 10px 0 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the overlay color and opacity as needed */
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff; /* Text color on the overlay */
    font-size: 1em; /* Adjust font size as needed */
    font-weight: bold;
    text-align: center;
    z-index: 1;
}

.landingCardGIF:hover::after {
    opacity: 1;
}

.landingCardGIF .card-footer {
    position: relative;
    z-index: 2; /* Ensure the footer is positioned above the overlay */
}

.landingCardIMAGE::after {
    content: '"A cat wearing street clothes at a city, natural light, hd, 8K UHD"';
    position: absolute;
    border: 2px solid purple;
    border-radius: 10px 10px 0 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the overlay color and opacity as needed */
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff; /* Text color on the overlay */
    font-size: 1em; /* Adjust font size as needed */
    font-weight: bold;
    text-align: center;
    z-index: 1;
}

.landingCardIMAGE:hover::after {
    opacity: 1;
}

.landingCardIMAGE .card-footer {
    position: relative;
    z-index: 2; /* Ensure the footer is positioned above the overlay */
}

.landingCardSTICKER::after {
    content: '"A cute rainbow unicorn"';
    position: absolute;
    border: 2px solid purple;
    border-radius: 10px 10px 0 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the overlay color and opacity as needed */
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff; /* Text color on the overlay */
    font-size: 1em; /* Adjust font size as needed */
    font-weight: bold;
    text-align: center;
    z-index: 1;
}

.landingCardSTICKER:hover::after {
    opacity: 1;
}

.landingCardSTICKER .card-footer {
    position: relative;
    z-index: 2; /* Ensure the footer is positioned above the overlay */
}




.landingCardColorize::after {
    content: '';
    position: absolute;
    border: 2px solid purple;
    border-radius: 10px 10px 0 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0); /* Adjust the overlay color and opacity as needed */
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff; /* Text color on the overlay */
    font-size: 1em; /* Adjust font size as needed */
    font-weight: bold;
    text-align: center;
    z-index: 1;
}

.landingCardColorize:hover::after {
    opacity: 1;
}

.landingCardColorize img.secondColorizeImage {
    display: none;
}

.landingCardColorize:hover img {
    display: none;
}

.landingCardColorize .card-footer {
    position: relative;
    z-index: 2; /* Ensure the footer is positioned above the overlay */
}

.landingCardColorize:hover img.secondColorizeImage {
    display: block;
    transition-duration: 1s;
}

.landingCardColorize img {
    transition-duration: 1s;
    display: block;
}

.landingCardRestoration::after {
    content: '';
    position: absolute;
    border: 2px solid purple;
    border-radius: 10px 10px 0 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0); /* Adjust the overlay color and opacity as needed */
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff; /* Text color on the overlay */
    font-size: 1em; /* Adjust font size as needed */
    font-weight: bold;
    text-align: center;
    z-index: 1;
}

.landingCardRestoration:hover::after {
    opacity: 1;
}

.landingCardRestoration img.secondRestorationImage {
    display: none;
}

.landingCardRestoration:hover img {
    display: none;
}

.landingCardRestoration .card-footer {
    position: relative;
    z-index: 2; /* Ensure the footer is positioned above the overlay */
}

.landingCardRestoration:hover img.secondRestorationImage {
    display: block;
    transition-duration: 1s;
}

.landingCardRestoration img {
    transition-duration: 1s;
    display: block;
}

.cardFooterButton {
    border-radius: 10px;
    background-color: blueviolet;
    transition-duration: 0.4s;
    color: white;
}

.cardFooterButton:hover {
    border-radius: 10px;
    background-color: blue;
    color: white;
}

.cardFooterIMAGEButton {
    background-color: blueviolet;
    color: white;
    transition-duration: 0.4s;
}

.cardFooterIMAGEButton:hover {
    background-color: blue;
    color: white;
}

.cardFooterCOLORIZEButton {
    border-radius: 10px;
    border: 2px solid blueviolet;
    background-color: blueviolet;
    transition-duration: 0.4s;
    vertical-align: center;
    color: white;
}

.cardFooterCOLORIZEButton:hover {
    border-radius: 10px;
    border: 2px solid blue;
    background-color: blue;
    color: white;
}

.landingInput {
    font-size: 1em;
}

input[placeholder] {
    text-overflow: ellipsis;
}

::-moz-placeholder {
    text-overflow: ellipsis;
}

/* firefox 19+ */
input:-moz-placeholder {
    text-overflow: ellipsis;
}

.landingCard::after {
    content: "";
    position: absolute;
    border: 2px solid purple;
    border-radius: 10px 10px 0 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white; /* Text color on the overlay */
    font-size: 1em; /* Adjust font size as needed */
    font-weight: bold;
    text-align: center;
    z-index: 1;
}

.landingCard:hover::after {
    opacity: 1;
}

.landingCard .card-footer {
    position: relative;
    z-index: 2; /* Ensure the footer is positioned above the overlay */
}


.showcaseColumn {
    max-width: 200px;
    padding: 10px;
}

.showcaseColumn img {
    border-radius: 10px;
    margin: 20px 20px;
}

.trans {
    transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    -webkit-transition: all 1s ease;
}

.lightbox {
    position: fixed;
    width: 100%;
    height: 100%;
    text-align: center;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 999;
    opacity: 0;
    pointer-events: none;
}

.lightbox img {
    max-width: 80%;
    max-height: 80%;
    position: relative;
    top: -100%;
    /* Transition */
    transition: all 1s ease;
    -moz-transition: all 1s ease;
    -ms-transition: all 1s ease;
    -o-transition: all 1s ease;
    -webkit-transition: all 1s ease;
}

.lightbox:target {
    outline: none;
    top: 0;
    opacity: 1;
    pointer-events: auto;
    transition: all 1.2s ease;
    -moz-transition: all 1.2s ease;
    -ms-transition: all 1.2s ease;
    -o-transition: all 1.2s ease;
    -webkit-transition: all 1.2s ease;
}

.lightbox:target img {
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);

}

.overlay-container {
    position: relative;
    display: inline-block;
}

.overlay-text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: transform 0.1s ease-in-out, opacity 1s ease-in-out;
    background: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px;
    text-align: center;
}

.overlay-container:hover .overlay-text {
    transform: translateY(0);
    opacity: 1;
}