.wrapper-numbers {
  color: #fff;
  background: #141E30;
  background: -webkit-linear-gradient(to right, #243B55, #141E30);
  background: linear-gradient(to right, #243B55, #141E30);
  padding: 60px 0 45px;
  text-align: center;
  font-size: 16px;
  overflow: hidden;
}

.header-numbers {
  margin-bottom: 40px;
}

.wrapper-numbers h1, .wrapper-numbers h2, .wrapper-numbers h3, .wrapper-numbers h4 {
  margin: 0 0 15px;
  color: #fff;
}

.count, .countup i {
  font-size: 50px;
  text-shadow: 0 1px 3px rgba(0,0,0,.5), 0 4px 20px rgba(0,0,0,.5);
  line-height: 1;
}

.sup {
  font-size: 20px;
  vertical-align: top;
  display: inline-block;
}

.column {
  padding-top: 20px;
  padding-bottom: 20px;
}

p {
}

